<template>
  <div></div>
</template>

<script>
import {resetHelpScout} from "@/services/auth-user";
import {getHeader, logoutUrl} from "@/config/config";
import environment from "@/environment";
import {mapActions} from "vuex";

export default {
  name: 'Logout',
  created() {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    if (authUser?.isHelpScoutInitialized) {
      resetHelpScout(authUser);
    }

    typeof window.Intercom === 'function' && window.Intercom('shutdown');

    this.$http.post(logoutUrl, { user_id: authUser.id }, { headers: getHeader() }).finally(() => {
      localStorage.removeItem('auth_ehealth');
      localStorage.removeItem('features');
      if (environment.isKeycloakIntegration) {
        this.$keycloak.logoutFn();
      }
      window.getApp.$emit('APP_LOGOUT');
      this.loadConfigFromBrand();
      this.closeAccountSession();
    });
  },
  methods: {
    ...mapActions('app', ['loadConfigFromBrand']),
    ...mapActions('account', ['closeAccountSession']),
  }
}
</script>
